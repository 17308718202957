<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>供销企业管理</el-breadcrumb-item>
        <el-breadcrumb-item>供销企业列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20"  style="text-align:left;">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="供销企业名称"
              v-model="page.supply_name"
              clearable
              @clear="onSubmit(page.warehouse_name)"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="联系人"
              v-model="page.concat"
              clearable
              @clear="onSubmit(page.warehouse_name)"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" size="medium"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-upload
              class="upload"
              :multiple="false"
              action="#"
              ref="upload"
              accept=".xlsx"
              :limit="1"
              :show-file-list="false"
              :before-upload="beforeUpload"
              style="float: right"
            >
              <el-button type="success" size="medium" @click="batchAdd"
                >批量导入</el-button
              >
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="warning" @click="downloadInfo(1)"
              >下载模板</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search mt20">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="供销企业名称"
                v-model="page.supply_name"
                clearable
                class="input-with-select"
                @clear="onSubmit(page.warehouse_name)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                      placeholder="联系人"
                      v-model="page.concat"
                      clearable
                      class="input-with-select"
                      @clear="onSubmit(page.warehouse_name)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item size="medium">
              <el-button
                type="primary"
                @click="onSubmit"
                class="general_bgc general_border cfff"
                >查询</el-button
              >
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item>
              <el-upload
                      class="upload"
                      :multiple="false"
                      action="#"
                      ref="upload"
                      accept=".xlsx"
                      :limit="1"
                      :show-file-list="false"
                      :before-upload="beforeUpload"
                      style="float: right">
                <el-button type="success" size="medium" @click="batchAdd"
                >批量导入</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item>
              <el-button size="medium" type="warning" @click="downloadInfo(1)">下载模板</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" id="out-table">
          <el-table-column
            fixed
            align="center"
            width="100"
            ref="multipleTable"
            type="index"
            label="序号"
          >
          </el-table-column>

          <el-table-column
            prop="supply_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="供销单位名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="contact"
            min-width="120"
            :show-overflow-tooltip="true"
            label="联系人"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="contact_info"
            min-width="120"
            :show-overflow-tooltip="true"
            label="联系人电话"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="credit_url"
            min-width="80"
            :show-overflow-tooltip="true"
            label="营业执照"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handlePictureCardPreview(scope.row.credit_url)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="record_url"
            min-width="80"
            :show-overflow-tooltip="true"
            label="备案证"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handlePictureCardPreview(scope.row.record_url)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="license_url"
            min-width="80"
            :show-overflow-tooltip="true"
            label="许可证"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handlePictureCardPreview(scope.row.license_url)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="credit_code"
            min-width="160"
            :show-overflow-tooltip="true"
            label="统一社会信用代码"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
            prop="company_supply_id"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib red"
                size="mini"
                @click="deleteInfo(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog :visible.sync="showViewer">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import axios from "@/util/api";
import { local } from "@/util/util";
import { mapState } from "vuex";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {},
  data() {
    return {
      files: "",
      user: {},
      page: {
        supply_name: "", //供销单位的名称
        concat: "", //联系人
        pager_offset: "0",
        pager_openset: "10",
      },
      timer: "",
      loading: false,
      currentPage: 1,
      total: 0,
      tableData: [],
      activeName: "1",
      company_id: "",
      url: "",
      uploadVis: false,
      showViewer: false,
      dialogImageUrl: "",
    };
  },
  filters: {},
  computed: {
    ...mapState({
      carState: (state) => state.carManage.carState,
    }),
  },
  mounted() {
    this.page = Object.assign({}, this.page);
    this.user = local.get("user");
    this.page.company_id = String(local.get("company_id"));
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.init(this.page);
  },
  methods: {
    beforeUpload(file) {
      // console.log(file,'文件');
      this.files = file;
      const extension2 = file.name.split(".")[1] === "xlsx";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!extension2) {
        this.$message.warning("上传模板只能是 xlsx格式!");
        return;
      }
      if (!isLt10M) {
        this.$message.warning("上传模板大小不能超过 10MB!");
        return;
      }
      this.fileName = file.name;
      setTimeout(() => {
        this.submitUpload();
      }, 500);
      return false; // 返回false不会自动上传
    },
    success() {
      this.$message.success("上传成功");
    },
    // 上传excel
    submitUpload() {
      //console.log('上传'+this.files.name)
      if (this.fileName == "") {
        this.$message.warning("请选择要上传的文件！");
        return false;
      }
      let fileFormData = new FormData();

      fileFormData.append("code", "t_pathology_info_excel");
      fileFormData.append("description", "供销单位批量上传");
      fileFormData.append("file", this.files, this.fileName);
      //filename是键，file是值，就是要传的文件，test是要传的文件名
      fileFormData.append("company_id", this.page.company_id);
      // 执行上传excel
      let id = "";
      axios
        .uploadFile("/pc/company-supply/importExcel", fileFormData)
        .then((resp) => {
          if (resp.code != 200) {
            this.$message.error("excel上传失败，请重新上传");
          }
          if (resp.data) {
            this.$message.warning(
              "该Excel中已经是与该公司具有供销单位关系的企业有：" +
                resp.data.length +
                "家"
            );
          } else {
          }
          this.init(this.page);
        })
        .catch((e) => {
          this.$message.error("excel上传失败，请重新上传");
        })
        .finally(() => {
          if (id) {
            // 触发生成订单实体数据
            this.generateBill(id);
          }
        });
    },
    deleteInfo(row) {
      //将供销单位的id传入后台进行删除
      axios
        .post("/pc/company-supply/delete", {
          company_supply_id: row.company_supply_id,
        })
        .then((res) => {
          res = res.data;
          if (res) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
          } else {
            this.message({
              type: "error",
              message: "删除失败",
            });
          }
          this.init(this.page);
        })
        .catch((res) => {
          this.message({
            type: "error",
            message: "操作异常",
          });
        });
    },
    handlePictureCardPreview(url) {
      if (url) {
        this.dialogImageUrl = url;
        this.showViewer = true;
      } else {
        this.$message.warning("没有上传证件！");
      }
    },
    init(params) {
      axios.get("/pc/company-supply/list", params).then((v) => {
        this.tableData = v.data.company_list;
        this.total = v.data.pager_count;
      });
    },
    downloadInfo(param) {
      axios
        .fileget("/pc/download/template", { fileName: "supply.xlsx" })
        .then((res) => {});
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.init(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.init(this.page);
    },
    //批量导入
    batchAdd() {
      this.uploadVis = true;
    },
    //编辑
    goUpdate(row) {
      this.$router.push({
        name: "WarehouseListAdd",
        query: { warehouse_id: row.id, nature: row.nature },
      });
    },
    handleStateChange(val, row) {
      const { id } = row;
      let car_state = val + "";
      axios.put("", { car_id: id + "", car_state }).then((response) => {
        this.init(this.page);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
